var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fileWrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "添加包", visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                action: "/acb/2.0/bacb/upgrade/uploadFile",
                headers: _vm.uploadData,
                name: "image",
                "on-remove": _vm.handleRemove,
                "on-success": _vm.success,
                "on-error": _vm.error,
                "on-progress": _vm.progress,
                "before-upload": _vm.beforeUpload,
                "file-list": _vm.fileList,
                "auto-upload": false,
                limit: 1,
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "trigger", size: "small", type: "primary" },
                  slot: "trigger",
                },
                [_vm._v("选取文件")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.submitUpload },
                },
                [_vm._v(" 上传到服务器")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }